import { useTranslation, Trans } from "next-i18next";
import Image from "next/image";
import { PageQuery } from "gql/graphql";

import Button from "components/Button/Button";
import CheckedListItem from "components/ListItem/CheckedListItem";
import GooglePodcasts from "components/Logos/GooglePodcasts";
import PremiumButton from "components/Button/PremiumButton";
import RadioGroup, { RadioGroupItem } from "components/RadioGroup/RadioGroup";
import TooltipOnHover from "components/Tooltip/TooltipOnHover";

import ArrowRight from "public/assets/arrow-right.svg";
import Lock from "public/assets/lock.svg";
import Stripe from "public/assets/stripe.svg";

export default function ProductDetails({
  data,
  productName,
  trialDays,
  selectedPaymentOption,
  setSelectedPaymentOption,
  setIsSignupDialogOpen,
  setIsSigninDialogOpen,
  slug,
}: {
  data: PageQuery;
  productName: string;
  trialDays: number;
  selectedPaymentOption: string;
  setSelectedPaymentOption: (value: string | number | object) => void;
  setIsSignupDialogOpen: (value: boolean) => void;
  setIsSigninDialogOpen: (value: boolean) => void;
  slug: string;
}) {
  const { t } = useTranslation("common");

  return (
    <>
      <h2 className="font-display text-[32px] font-bold leading-none lg:text-[40px]">
        {trialDays > 0 ? t("subscribe_header_trial") : t("subscribe_header")}
      </h2>
      <p className="mt-6 text-white/70">
        {trialDays > 0 ? (
          <Trans i18nKey="subscribe_subheader_trial">
            Enjoy <span className="text-white/90">{data?.page.hero.title}</span> for free until{" "}
            {data?.page.product.purchaseOptions[0].startDate}, then only{" "}
            {data?.page.product.purchaseOptions[0].price.displayPrice} per month thereafter.
          </Trans>
        ) : (
          <Trans i18nKey="subscribe_subheader">
            Enjoy <span className="text-white/90">{data?.page.hero.title}</span> for only{" "}
            {data?.page.product.purchaseOptions[0].price.displayPrice} per month.
          </Trans>
        )}
      </p>
      <p className="mt-2 text-white/70">{t("subscribe_subheader2")}</p>
      {slug == "thirdear" && (
        <p className="mt-2 text-white/70">Har du en promokod så fyller du den i nästa steg.</p>
      )}
      <ul className="mt-6 list-none space-y-4 font-medium lg:mt-8">
        {data?.page?.product?.features?.map((checkedListItem, index) => (
          <>
            {checkedListItem.type === "text" && (
              <CheckedListItem key={index}>{checkedListItem.content}</CheckedListItem>
            )}

            {checkedListItem.type === "localized_key" && (
              <CheckedListItem>
                <span className="flex items-center">
                  {/* <Trans i18nKey="listen on"> */}
                  Listen on <span className="sr-only">Spotify</span>
                  &nbsp;{" "}
                  <TooltipOnHover text={t("spotify")} className="h-6 w-6">
                    <Image
                      unoptimized={true}
                      alt="spotify"
                      loading="eager"
                      src="/assets/app-spotify-colorized.png"
                      width="24"
                      height="24"
                      className="inline-block transition hover:scale-90"
                      aria-hidden="true"
                      quality={100}
                    />
                  </TooltipOnHover>
                  ,<span className="sr-only">Apple Podcasts</span>
                  &nbsp;{" "}
                  <TooltipOnHover text={t("apple_podcasts")} className="h-6 w-6">
                    <Image
                      alt="apple podcasts"
                      unoptimized={true}
                      loading="eager"
                      src="/assets/app-apple-podcast-colorized.png"
                      width="24"
                      height="24"
                      className="inline-block transition hover:scale-90"
                      aria-hidden="true"
                      quality={100}
                    />
                  </TooltipOnHover>
                  , and
                  <span className="sr-only">Google Podcasts</span>
                  &nbsp;
                  <GooglePodcasts className="h-6 w-6" />
                  {/* </Trans> */}
                </span>
              </CheckedListItem>
            )}
          </>
        ))}
      </ul>
      <section className="mt-8">
        <RadioGroup
          name={"select-plan"}
          defaultValue={selectedPaymentOption}
          onChange={setSelectedPaymentOption}
          label={t("choose_plan")}
        >
          {data?.page.product.purchaseOptions.map((option) => (
            <RadioGroupItem key={option.identifier} value={option.identifier}>
              <span className="">{t(option.name)}</span>
              {/* <span className="ml-3 rounded-lg px-1.5 py-1 text-xs text-white/50 ring-2 ring-inset ring-white/10 transition ui-checked:bg-gradient-to-b ui-checked:from-[#3377FF] ui-checked:to-[#0055FF] ui-checked:text-white ui-checked:ring-transparent">
-              -15%
-            </span> */}
              <span className="ml-auto text-white/50 transition group-hover:!text-white ui-checked:text-white/90">
                {`${t("displayPrice", {
                  price: option.price.price,
                })} ${option.price.currency === "SEK" ? "kr" : option.price.currency} `}
                <span className="text-sm font-medium text-white/50 transition group-hover:text-white ui-checked:text-white/90">
                  /{t(option.billingFrequency.toLowerCase())}
                </span>
              </span>
            </RadioGroupItem>
          ))}
        </RadioGroup>
        {trialDays > 0 ? (
          <>
            <div className="mt-8">
              <PremiumButton
                fullWidth
                size="large"
                importance="premium"
                onClick={() => setIsSignupDialogOpen(true)}
              >
                {t("start_trial_button", {
                  days: data?.page.product.purchaseOptions[0].trialDays,
                })}
                <ArrowRight className="ml-2 fill-white" />
              </PremiumButton>
            </div>
            <p className="mt-6 text-center text-xs text-white/70">
              {t("start_trial_description", {
                title: productName,
                trialDays,
                price: data?.page.product.purchaseOptions.find(
                  ({ identifier }) => identifier == selectedPaymentOption
                )?.price.displayPrice,
                interval:
                  selectedPaymentOption == data?.page.product.purchaseOptions[0].identifier
                    ? t("month")
                    : t("year"),
              })}
            </p>
          </>
        ) : (
          <div className="mt-8">
            <PremiumButton
              fullWidth
              size="large"
              importance="premium"
              onClick={() => setIsSignupDialogOpen(true)}
            >
              {t("subscribe_header")}
              <ArrowRight className="ml-2 fill-white" />
            </PremiumButton>
          </div>
        )}
      </section>
      <div className="mt-8">
        <p className="text-center text-xs text-white/70">
          <Lock title="secure payments" className="mr-2 inline-block fill-white/70" />
          {t("secure_payments")}
          <Stripe title="stripe" className="ml-[6px] inline-block" />
        </p>
        <div className="mt-10 flex justify-center">
          <Button
            className="w-full lg:mx-auto lg:w-auto"
            size="small"
            importance="secondary"
            onClick={() => setIsSigninDialogOpen(true)}
          >
            {t("sign_in_button")}
          </Button>
        </div>
      </div>
    </>
  );
}
